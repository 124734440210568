<template>
  <section>
    <b-overlay :show="showOverlay" rounded="sm">
      <div v-if="isLoaded">
        <b-overlay
          :show="loadingSpinner"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="secondary"
          class="d-inline-block width100"
        >
          <div class="upload-container">
            <div class="toggle-list">
              <div class="toggle-upload">
                <button
                  v-if="validate60Meses"
                  @click.prevent="
                    (validate60Meses = false),
                      toggleUploadParameters('validate60Meses')
                  "
                >
                  <Icon :iconName="'toggle-on'" class="toggle-icon-green" />
                </button>
                <button
                  v-if="!validate60Meses"
                  @click.prevent="
                    (validate60Meses = true),
                      toggleUploadParameters('validate60Meses')
                  "
                >
                  <Icon :iconName="'toggle-off'" class="toggle-icon-gray" />
                </button>
                Validar 60 Meses
              </div>

              <div class="toggle-upload">
                <button
                  v-if="validateCnpj"
                  @click.prevent="
                    (validateCnpj = false),
                      toggleUploadParameters('validateCnpj')
                  "
                >
                  <Icon :iconName="'toggle-on'" class="toggle-icon-green" />
                </button>
                <button
                  v-if="!validateCnpj"
                  @click.prevent="
                    (validateCnpj = true),
                      toggleUploadParameters('validateCnpj')
                  "
                >
                  <Icon :iconName="'toggle-off'" class="toggle-icon-gray" />
                </button>
                Validar CNPJ
              </div>
            </div>

            <VueDropzone
              id="uploadComponent"
              class="vue-dropzone"
              ref="UploadComponentDropzone"
              :options="dropzoneOptions"
              @vdropzone-success="handleUploadSuccess"
              @vdropzone-error="handleUploadError"
              @vdropzone-file-added="handleFileAdded"
              @vdropzone-queue-complete="handleCompletedQueue"
              :useCustomSlot="true"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  Selecione um arquivo ou arraste aqui
                </h3>
                <div class="subtitle">Máximo de 100 arquivos por vez</div>
                <div class="subtitle">Speds contribuições e contábil</div>
              </div>
            </VueDropzone>

            <div id="fileList">
              <b-card class="upload-list" no-body>
                <b-button
                  class="upload-list-header"
                  block
                  v-b-toggle.accordion-1
                  variant="light"
                >
                  <div>Contribuições</div>
                  <div class="processJudicial" v-if="isJudicial">
                    <i class="bi bi-exclamation-triangle iconProcess"></i
                    >Empresa em processo judicial
                  </div>
                  <div class="processJudicial" v-if="isLucroPresumido">
                    <i class="bi bi-exclamation-triangle iconProcess"></i
                    >Empresa com regime Lucro Presumido
                  </div>
                  <div class="upload-list-header-buttons">
                    <div class="upload-list-count">
                      {{ this.contribuicoesFiles.length }} Arquivos Enviados
                    </div>
                    <button @click.prevent="deleteContribuicoesFiles($event)">
                      <Icon :iconName="'x'" class="nav-icon" />
                    </button>
                    <button>
                      <Icon :iconName="'chevron-down'" class="nav-icon" />
                    </button>
                  </div>
                </b-button>

                <b-collapse
                  class="upload-list-collapse"
                  id="accordion-1"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <ListUploadFiles
                    ref="contribuicoesFiles"
                    class="listaArquivosEnviados"
                    v-if="this.contribuicoesFiles.length > 0"
                    :listFiles="this.contribuicoesFiles"
                    :nCodOp="nCodOp"
                    :hasExSended="hasExcelSended"
                    @showOverlay="showOverlay = $event"
                    @cleanList="loadAllFileList()"
                  />
                </b-collapse>
              </b-card>

              <b-card class="upload-list" no-body>
                <b-button
                  class="upload-list-header"
                  block
                  v-b-toggle.accordion-2
                  variant="light"
                >
                  <div>Contábeis</div>
                  <div class="upload-list-header-buttons">
                    <div class="upload-list-count">
                      {{ this.contabilFiles.length }} Arquivos Enviados
                    </div>
                    <button @click.prevent="deleteContabilFiles($event)">
                      <Icon :iconName="'x'" class="nav-icon" />
                    </button>
                    <button>
                      <Icon :iconName="'chevron-down'" class="nav-icon" />
                    </button>
                  </div>
                </b-button>

                <b-collapse
                  class="upload-list-collapse"
                  id="accordion-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <ListUploadFiles
                    ref="contabilFiles"
                    class="listaArquivosEnviados"
                    v-if="this.contabilFiles.length > 0"
                    :listFiles="this.contabilFiles"
                    :nCodOp="nCodOp"
                    :hasExSended="hasExcelSended"
                    @showOverlay="showOverlay = $event"
                    @cleanList="loadAllFileList()"
                  />
                </b-collapse>
              </b-card>
            </div>
          </div>
        </b-overlay>
      </div>
      <div v-else>
        <div class="p-5 d-flex justify-content-center">
          <h1 class="text-secondary d-flex align-items-end">
            <span class="mx-3">Carregando</span>
            <DotAnimation />
          </h1>
        </div>
      </div>
    </b-overlay>
  </section>
</template>

<!-- <link rel="stylesheet" href="v-toaster/dist/v-toaster.css"></link>
<script src="v-toaster/dist/v-toaster.js"></script> -->

<script>
import DotAnimation from "@/Components/Utils/DotAnimation.vue";
import HelperService from "@/services/helper.service.js";
import ListUploadFiles from "@/Components/UploadComponent/ListUploadFiles.vue";
import UploadRestituicaoService from "@/services/uploadRestituicao.service.js";
import UploadInsumosService from "@/services/uploadInsumos.service.js";
import Vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Icon from "../Icon/icon.vue";

export default {
  name: "UploadComponentInsumos",
  components: {
    DotAnimation,
    ListUploadFiles,
    Icon,
    VueDropzone: Vue2Dropzone,
  },
  data() {
    return {
      showOverlay: false,
      loadingSpinner: false,
      isLoaded: false,
      tese: "",
      teseAcceptedFiles: "",
      nCodOp: "",
      removerPeriodo: false,
      isCompletedWithoutErrors: "",
      hasFilesSended: false,
      hasExcelSended: false,
      filesSuccessAdded: [],
      contabilFiles: [],
      contribuicoesFiles: [],
      isOpen: false,
      toOpenList: {
        file: [],
        response: [],
      },
      periodoAdded: [],
      dropzoneOptions: {
        url: process.env.VUE_APP_URL_API + "/Insumos/uploadFiles/",
        addRemoveLinks: false,
        duplicateCheck: true,
        parallelUploads: 100,
        autoProcessQueue: true,
        acceptedFiles: ".xlsx, .txt",
        paramName: "files",
        params: {
          NCodOp: this.$route.params.nCodOp,
          ValidarCNPJ: true,
          Validar60Meses: true,
          RemoveDuplicates: true,
        },
        timeout: 0,
        maxFiles: 1000,
        maxFilesize: 999999999,
      },
      isJudicial: false,
      validate60Meses: true,
      validateCnpj: true,
      removeDuplicates: true,
      isLucroPresumido: false,
    };
  },
  mounted() {
    this.isLoaded = false;
    this.tese = this.$route.fullPath.split("/")[2];
    this.nCodOp = this.$route.params.nCodOp;
    this.loadAllFileList();

    switch (this.tese) {
      case "restituicao":
        this.dropzoneOptions.acceptedFiles = ".txt, .xlsx";
        break;
      case "inss":
        this.dropzoneOptions.acceptedFiles = ".txt, .zip, .xlsx";
        break;
      case "icms":
        this.dropzoneOptions.acceptedFiles = ".txt, .zip, .xlsx";
        break;
      case "exclusao":
        this.dropzoneOptions.acceptedFiles = ".txt, .zip, .xlsx";
        break;
      case "insumos":
        this.dropzoneOptions.acceptedFiles = ".txt, .zip, .xlsx";
        break;
    }
    return this.dropzoneOptions;
  },
  methods: {
    async loadAllFileList() {
      try {
        let insumosFilesList = await UploadInsumosService.getInsumosFilesSent(
          this.nCodOp
        );
        this.contabilFiles = await insumosFilesList.data.dados
          .listaSpedContabil0000;
        this.contribuicoesFiles = await insumosFilesList.data.dados
          .listaSpedContribuicoes0000;
        for (let index = 0; index < this.contribuicoesFiles.length; index++) {
          if (this.contribuicoesFiles[index].isJudicial == 1) {
            this.isJudicial = true;
          }
        }

        if (insumosFilesList.data.mensagem !== null) {
          if (insumosFilesList.data.mensagem[0] == "lucro presumido") {
            console.log("entrou");
            this.isLucroPresumido = true;
          }
        }
        this.isLoaded = true;
        this.loadingSpinner = false;
      } catch (error) {
        this.loadingSpinner = false;
        // return HelperService.callMakeToast(error, "error");
        this.$toast.open({
          message: error,
          type: "error",
          duration: 2000,
        });
      }
    },
    toggleUploadParameters(par) {
      switch (par) {
        case "validate60Meses":
          this.dropzoneOptions.params.Validar60Meses = this.validate60Meses;
          break;
        case "validateCnpj":
          this.dropzoneOptions.params.ValidarCNPJ = this.validateCnpj;
          break;
        case "removeDuplicates":
          this.dropzoneOptions.params.RemoveDuplicates = this.removeDuplicates;
          break;
      }
    },
    async loadContabilFileList() {
      try {
        let fileList = await UploadInsumosService.getContabilFiles(this.nCodOp);
        if (fileList.status == 200) {
          this.contabilFiles = fileList.data.dados; //[1]
          this.isLoaded = true;
        }
        if (
          this.contabilFiles.length != undefined &&
          this.contabilFiles.length > 0
        )
          this.hasFilesSended = true;
      } catch (error) {
        // return HelperService.callMakeToast(error, "error");
        this.$toast.open({
          message: error,
          type: "error",
          duration: 2000,
        });
      }
    },

    async loadContribuicoesFileList() {
      try {
        let fileList = await UploadInsumosService.getContribuicoesFiles(
          this.nCodOp
        );
        if (fileList.status == 200) {
          this.ContribuicoesFiles = fileList.data.dados; //[1]
          this.isLoaded = true;
        }
        if (
          this.contribuicoesFiles.length != undefined &&
          this.contribuicoesFiles.length > 0
        )
          this.hasFilesSended = true;
      } catch (error) {
        // return HelperService.callMakeToast(error, "error");
        this.$toast.open({
          message: error,
          type: "error",
          duration: 2000,
        });
      }
    },

    loadPasso2() {
      this.loadingSpinner = true;
      this.$emit("passo2");
    },

    async deleteContribuicoesFiles(e) {
      e.stopPropagation();
      let contribuicoesFilesList = this.$refs.contribuicoesFiles;
      this.isJudicial = false;
      contribuicoesFilesList.clearContribuicoesInsumosFiles(this.nCodOp);
    },

    async deleteContabilFiles(e) {
      e.stopPropagation();
      let contabilFilesList = this.$refs.contabilFiles;
      contabilFilesList.clearContabilFiles(this.nCodOp);
    },

    handleUploadSuccess(file, response) {
      try {
        this.loadingSpinner = false;
        let message = "";
        let splitType = file.upload.filename.includes("_") ? "_" : "-";
        let tipoArquivo = file.upload.filename.split(splitType)[0].trim();
        console.log("tipoArquivo", tipoArquivo);
        if (tipoArquivo == "PISCOFINS") {
          let dataFile = file.upload.filename.split(splitType)[1].trim();
          let year = dataFile.slice(0, 4);
          let mes = dataFile.slice(4, 6);
          message = "Arquivo de " + mes + "/" + year + " enviado com sucesso";
        } else {
          let dataFile = file.upload.filename.split(splitType)[3].trim();
          let year = dataFile.slice(0, 4);
          let mes = dataFile.slice(4, 6);
          message = "Arquivo de " + mes + "/" + year + " enviado com sucesso";
        }

        this.$refs.UploadComponentDropzone.removeFile(file);
        this.loadAllFileList();
        // return HelperService.callMakeToast(message, "success", 5000);
        this.$toast.open({
          message: message,
          type: "success",
          duration: 2000,
        });
      } catch (error) {
        console.error("error ->", error);
        // return HelperService.callMakeToast(error, "error", 5000);
        this.$toast.open({
          message: error,
          type: "error",
          duration: 2000,
        });
      }
    },

    handleUploadError(file, response, xhr) {
      var error = "Erro ao enviar";
      if (response.mensagem != null) error = response.mensagem[0];
      //$('.data-dz-errormessage span ').text(error);
      //TODO: validar qual é o tipo de erro de forma mais específica
      if (response.dados != null && response.dados.length == 2) {
        if (this.isOpen) {
          this.toOpenList.file.push(file);
          this.toOpenList.response.push(response);
        } else {
          this.isOpen = true;

          let splitType = file.upload.filename.includes("_") ? "_" : "-";
          let tipoArquivo = file.upload.filename.split(splitType)[0].trim();
          let year = "";
          if (tipoArquivo == "PISCOFINS") {
            let dataFile = file.upload.filename.split(splitType)[1].trim();
            year = dataFile.slice(0, 4);
          } else {
            let dataFile = file.upload.filename.split(splitType)[3].trim();
            year = dataFile.slice(0, 4);
          }

          this.$swal({
            icon: "info",
            title: "CNPJs divergentes !!",
            html: `Arquivo de <strong>${year}</strong> </br> CNPJ do NCodOp: <strong>${response.dados[0]}</strong> </br> CNPJ do Sped: <strong>${response.dados[1]}</strong>
                        </br> Deseja continuar mesmo assim ?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, desejo continuar!",
            cancelButtonText: `Cancelar`,
          }).then((result) => {
            if (result.value) {
              this.loadingSpinner = true;

              let splitType = file.upload.filename.includes("_") ? "_" : "-";
              let periodoToRemove = file.upload.filename
                .split(splitType)[3]
                .trim();
              let index = this.periodoAdded.indexOf(periodoToRemove);
              this.periodoAdded.splice(index, 1);
              this.$refs.UploadComponentDropzone.removeFile(file);
              this.$refs.UploadComponentDropzone.manuallyAddFile(file, "");
              this.callModalIfExits();

              UploadInsumosService.uploadFiles(
                file,
                this.tese,
                this.nCodOp,
                true, //ignorar CNPJ
                this.validate60Meses
              )
                .then((response) => {
                  this.loadingSpinner = false;
                  if (response.status == 200) {
                    let splitType = file.upload.filename.includes("_")
                      ? "_"
                      : "-";
                    let filename = file.upload.filename
                      .split(splitType)[3]
                      .trim();
                    let year = filename.slice(0, 4);
                    this.$refs.UploadComponentDropzone.removeFile(file);
                    let message = "Arquivo de " + year + " enviado com sucesso";
                    this.loadAllFileList();
                    // return HelperService.callMakeToast(message, "success");
                    this.$toast.open({
                      message: message,
                      type: "success",
                      duration: 2000,
                    });
                  }
                })
                .catch((error) => {
                  this.loadingSpinner = false;
                  this.$refs.UploadComponentDropzone.removeFile(file);
                  if (error.response.data != null) {
                    console.log("e - ", error.response.data);
                    // HelperService.callMakeToast(error.response.data.mensagem[0],"error");
                    this.$toast.open({
                      message: error.response.data.mensagem[0],
                      type: "error",
                      duration: 2000,
                    });
                  } else {
                    // HelperService.callMakeToast("Erro ao enviar", "error");
                    this.$toast.open({
                      message: "Erro ao enviar",
                      type: "success",
                      duration: 2000,
                    });
                  }
                  this.callModalIfExits();
                });
            } else {
              this.$refs.UploadComponentDropzone.removeFile(file);
              this.callModalIfExits();
            }
          });
        }
      } else {
        this.$refs.UploadComponentDropzone.removeFile(file);
        // return HelperService.callMakeToast(error, "error");
        this.$toast.open({
          message: error,
          type: "error",
          duration: 2000,
        });
      }
    },

    handleCompletedQueue() {
      this.periodoAdded = [];
    },

    handleFileAdded(file) {
      let arrayExt = file.upload.filename.split(".");
      let fileExt = arrayExt.pop();

      if (fileExt == "txt") {
        let splitType = file.upload.filename.includes("_") ? "_" : "-";
        let tipoArquivo = file.upload.filename.split(splitType)[0].trim();

        if (tipoArquivo == "PISCOFINS") {
          let periodo = file.upload.filename.split(splitType)[1].trim();
          let year = periodo.slice(0, 4);
          if (this.periodoAdded.includes(periodo)) {
            let message =
              "Periodo de " +
              periodo +
              " removido na validação de duplicidade.";
            // HelperService.callMakeToast(message, "info");
            this.$toast.open({
              message: message,
              type: "info",
              duration: 2000,
            });
            return this.$refs.UploadComponentDropzone.removeFile(file);
          }
          return this.periodoAdded.push(periodo);
        } else {
          let periodo = file.upload.filename.split(splitType)[3].trim();
          let year = periodo.slice(0, 4);
          if (this.periodoAdded.includes(periodo)) {
            let message =
              "Periodo de " +
              periodo +
              " removido na validação de duplicidade.";
            // HelperService.callMakeToast(message, "info");
            this.$toast.open({
              message: message,
              type: "info",
              duration: 2000,
            });
            return this.$refs.UploadComponentDropzone.removeFile(file);
          }
          return this.periodoAdded.push(periodo);
        }
      }
    },

    callModalIfExits() {
      this.isOpen = false;
      try {
        if (this.toOpenList.file.length > 0) {
          var responseObj = JSON.parse(
            JSON.stringify(this.toOpenList.response[0])
          );
          this.handleUploadError(this.toOpenList.file[0], responseObj, null);
          this.toOpenList.response.splice(0, 1);
          this.toOpenList.file.splice(0, 1);
        }
      } catch (error) {
        // return HelperService.callMakeToast(error, "error");
        this.$toast.open({ message: error, type: "error", duration: 2000 });
      }
    },
  },
};
</script>

<style scoped>
.width100 {
  width: 100%;
}
#uploadComponent {
  border: 2px dashed #aaa !important;
  border-radius: 20px;
  color: #aaa;
  background-color: #eee !important;
  width: 60% !important;
  min-height: 180px !important;
  max-height: 230px !important;
  overflow-y: auto !important;
  flex-grow: 1;
  margin: 30px 0px;
  box-shadow: 18px 18px 36px #bebebe, -18px -18px 36px #ffffff;
}
#fileList {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 0px;
  width: 90%;
}
.toggle-list {
  display: flex;
  flex-direction: row;
}
.toggle-upload {
  margin: 40px 30px 0px 30px;
  display: flex;
  align-items: center;
}
.toggle-upload button {
  background: none;
  border: none;
}
.toggle-icon-green {
  font-size: 30px;
  margin: 0px 5px;
  vertical-align: center;
  color: #2bb673;
}
.toggle-icon-gray {
  font-size: 30px;
  margin: 0px 5px;
  vertical-align: center;
  color: #9b9b9b;
}
.upload-list {
  margin: 20px;
  border: none;
  box-shadow: 18px 18px 36px #bebebe, -18px -18px 36px #ffffff;
  border-radius: 10px;
}
.upload-list-header {
  background-color: white;
  color: black;
  border: none;
  border-radius: 10px;
  border-bottom: #233873;
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  padding-left: 30px;
}
.upload-list-header-buttons button {
  background: none;
  border: none;
}

.upload-list-header div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.upload-list-count {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding: 15px;
  letter-spacing: -0.02em;

  color: #8e8e8e;
}
.nav-icon {
  padding: 15px;
}

.toast-error-tj {
  background-color: aqua !important;
}
VueDropzone .dz-preview .dz-details {
  background-color: #fff !important;
  color: black !important;
}

.upload-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  min-width: 800px;
  width: 100%;
}

.btn-tj-blue {
  color: #233873 !important;
  border: 1px solid #233873 !important;
}
.btn-tj-blue:hover {
  background-color: #233873 !important;
  color: #fff !important;
}
#customdropzone {
  background-color: orange;
  font-family: "Arial", sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color 0.2s linear;
  height: 200px;
  padding: 40px;
}
#customdropzone .dz-preview {
  width: 160px;
  display: inline-block;
}
#customdropzone .dz-preview .dz-image {
  width: 80px;
  height: 80px;
  margin-left: 40px;
  margin-bottom: 10px;
}
#customdropzone .dz-preview .dz-image > div {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-size: contain;
}
#customdropzone .dz-preview .dz-image > img {
  width: 100%;
}
#customdropzone .dz-preview .dz-details {
  color: white;
  transition: opacity 0.2s linear;
  text-align: center;
}
#customdropzone .dz-success-mark,
.dz-error-mark,
.dz-remove {
  display: none;
}

.listasEnviados {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.listaArquivosEnviados {
  flex-grow: 1;
  margin: 15px;
}

.processJudicial {
  font-size: small;
  /* background-color: rgb(226, 240, 35); */
  border-radius: 10px;
  padding-right: 5px;
  color: red;
}

.iconProcess {
  padding-left: 5px;
  padding-right: 5px;
}
</style>
