<template>
  <section>
    <div v-if="isLoaded">
      <div
        class="d-flex flex-wrap flex-column align-itens-center align-content-center"
      >
        <h4 class="text-center text-secondary">
          Selecionar insumos pra cálculo
        </h4>
      </div>

      <div class="d-flex justify-content-center" v-if="!isCreditosUtilizados">
        <b-input
          class="w-25 mt-4 ml-2 mr-3 mb-3 form-control"
          v-model="searchWord"
          placeholder="Pesquisar insumos..."
          type="text"
          @keyup="filterByWord()"
        ></b-input>
        <b-form-select
          class="w-25 mt-4 mb-3 form-control"
          @change="filterByStatus($event)"
          :value="0"
          :options="statusDesc"
          v-model="selectStatus"
        ></b-form-select>
      </div>

      <div class="d-flex justify-content-center" v-if="isCreditosUtilizados">
        <b-input
          class="w-25 mt-4 ml-2 mr-3 mb-3 form-control"
          v-model="searchWordCreditos"
          placeholder="Pesquisar créditos..."
          type="text"
          @keyup="filterCreditoByWord()"
        ></b-input>
        <b-form-select
          class="w-25 mt-4 mb-3 form-control"
          @change="filterCreditoByYear($event)"
          :value="0"
          :options="yearsCreditos"
          v-model="selectStatusCred"
        ></b-form-select>
      </div>

      <div class="d-flex justify-content-end">
        <h5 class="mt-2 text-secondary" v-if="!isCreditosUtilizados">
          {{ this.totalItensInsumos }} insumos encontrados
        </h5>

        <h5 class="mt-2 text-secondary" v-if="isCreditosUtilizados">
          {{ this.totalItensCreditos }} créditos encontrados
        </h5>
      </div>

      <div class="d-flex justify-content-end">
        <h6
          class="mt-2 text-secondary"
          v-if="listItensEmProcessamento.length == 1"
        >
          {{ this.listItensEmProcessamento.length }} insumo sendo processado
        </h6>

        <h6
          class="mt-2 text-secondary"
          v-if="listItensEmProcessamento.length > 1"
        >
          {{ this.listItensEmProcessamento.length }} insumos sendo processados
        </h6>
      </div>

      <b-tabs class="titleColor">
        <b-tab class="pt-2" @click.stop="loadListaInsumos()">
          <template #title>
            Insumos
            <span class="badge badge-pill badge-primary">
              <span class="sr-only">Insumos</span>
            </span>
          </template>

          <div class="justify-content-center">
            <table v-if="hasInsumos" class="table">
              <thead>
                <tr>
                  <th scope="col">Código CTA</th>
                  <th scope="col">Descrição do Insumo</th>
                  <th scope="col">Qtd. i250</th>
                  <th scope="col">Valor da Verba</th>
                  <th scope="col" style="text-align: center">Status</th>
                  <th scope="col" style="text-align: center">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(insumo, index) in listInsumos"
                  :key="index"
                  @click.stop="getItensModal(insumo)"
                  style="cursor: pointer"
                >
                  <td>#{{ insumo.codCta }}</td>
                  <td>{{ insumo.cta }}</td>
                  <td>{{ insumo.i250s }}</td>
                  <td>{{ formatMoney(insumo.valorDebito) }}</td>
                  <td
                    style="
                      text-align: start;
                      display: flex;
                      flex-direction: row;
                      max-width: 200px;
                      flex-wrap: nowrap;
                    "
                  >
                    <Icon
                      v-if="statusDesc[insumo.status] == 'Não visualizado'"
                      :iconName="'circle-fill'"
                      class="status-icon"
                      style="color: #d1d1d1; margin-right: 10px"
                    />
                    <Icon
                      v-if="statusDesc[insumo.status] == 'Selecionado'"
                      :iconName="'circle-fill'"
                      class="status-icon"
                      style="color: #303084; margin-right: 10px"
                    />
                    <Icon
                      v-if="statusDesc[insumo.status] == 'Finalizado'"
                      :iconName="'circle-fill'"
                      class="status-icon"
                      style="color: #2bb673; margin-right: 10px"
                    />
                    <Icon
                      v-if="statusDesc[insumo.status] == 'Erro'"
                      :iconName="'circle-fill'"
                      class="status-icon"
                      style="color: #d64848; margin-right: 10px"
                    />
                    <Icon
                      v-if="
                        statusDesc[insumo.status] == 'Processando' ||
                        statusDesc[insumo.status] == 'Processando Remover'
                      "
                      :iconName="'circle-fill'"
                      class="status-icon"
                      style="color: #343434; margin-right: 10px"
                    />
                    {{ statusDesc[insumo.status] }}
                  </td>
                  <td style="text-align: center">
                    <b-button
                      v-if="
                        statusDesc[insumo.status] == 'Não visualizado' ||
                        statusDesc[insumo.status] == 'Erro'
                      "
                      variant="btn btn-outline-blue"
                      @click.stop="i250FastConfirm($event, insumo)"
                      >Selecionar</b-button
                    >

                    <b-button
                      v-if="
                        statusDesc[insumo.status] == 'Selecionado' ||
                        statusDesc[insumo.status] == 'Erro Remover' ||
                        statusDesc[insumo.status] == 'Finalizado'
                      "
                      variant="btn btn-outline-danger"
                      @click.prevent="removeInsumo($event, insumo)"
                      >Remover</b-button
                    >

                    <b-button
                      disabled
                      v-if="
                        statusDesc[insumo.status] == 'Processando' ||
                        statusDesc[insumo.status] == 'Processando Remover'
                      "
                      variant="btn btn-outline-gray"
                      @click.stop=""
                      >Aguarde</b-button
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab class="pt-2" @click.stop="loadCreditos()">
          <template #title>
            Créditos utilizados
            <span class="badge badge-pill badge-primary">
              <span class="sr-only">Créditos utilizados</span>
            </span>
          </template>

          <table class="table">
            <thead>
              <tr>
                <th><strong>Data Apurada</strong></th>
                <th><strong>Descrição da verba</strong></th>
                <th><strong>Valor</strong></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in listCreditosUtilizados" :key="index">
                <td>
                  {{ formatDatePTBR(item.data_Apurada) }}
                </td>
                <td>
                  {{ item.descricao }}
                </td>
                <td>
                  {{ formatMoney(item.valor_Verba) }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-tab>
      </b-tabs>
    </div>

    <div v-else>
      <div class="p-5 d-flex justify-content-center">
        <h1 class="text-secondary d-flex align-items-end">
          <span class="mx-3">Carregando</span>
          <DotAnimation />
        </h1>
      </div>
    </div>

    <b-modal
      :title="i050selected.cta"
      size="xl"
      id="modal-exclusao-i250"
      ref="modal-exclusao-i250"
      scrollable
    >
      <b-tabs class="titleColor">
        <b-tab active class="pt-4" @click="reloadSearchModal()">
          <template #title>
            Insumos
            <span class="badge badge-pill badge-primary">
              {{ listI250Added.length }}
              <span class="sr-only">I250</span>
            </span>
          </template>

          <b-overlay :show="tablebusyi250">
            <div class="d-flex justify-content-left">
              <b-input
                class="w-25 mt-1 ml-2 mr-3 mb-4 form-control"
                v-model="searchWordModal"
                placeholder="Pesquisar insumo"
                type="text"
                @keyup="filterInsumoAdd()"
              ></b-input>

              <b-form-select
                class="w-25 mt-1 ml-2 mr-3 mb-4 form-control"
                @change="filterModalAddByYear($event)"
                :value="0"
                :options="yearsModal"
                v-model="selectAdded"
              ></b-form-select>

              <b-button
                variant="btn btn-outline-danger mt-1 ml-2 mr-3 mb-4"
                @click="removeI250Filter(listI250Added)"
              >
                Remover itens filtrados
              </b-button>
            </div>

            <table class="table">
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th>Data de lançamento</th>
                  <th>Valor do i250</th>
                  <th>Indicador de Créd.</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  style="cursor: pointer !important"
                  v-for="(item, index) in listI250Added"
                  :key="index"
                  @click.stop="removeI250(item)"
                >
                  <td>{{ item.hist }}</td>
                  <td>{{ item.i200DtLcto }}</td>
                  <td>{{ formatMoney(item.vlDc) }}</td>
                  <td>{{ item.indDc }}</td>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </b-tab>

        <b-tab class="pt-4" lazy @click="reloadSearchModal()">
          <template #title>
            Removidos
            <span class="badge badge-pill badge-primary">
              {{ listI250Removed.length }}
              <span class="sr-only">Removidos</span>
            </span>
          </template>

          <b-overlay :show="tablebusyi250">
            <div class="d-flex justify-content-left">
              <b-input
                class="w-25 mt-1 ml-2 mr-3 mb-4 form-control"
                v-model="searchWordModal"
                placeholder="Pesquisar insumo"
                type="text"
                @keyup="filterInsumoRemoved()"
              ></b-input>

              <b-form-select
                class="w-25 mt-1 ml-2 mr-3 mb-4 form-control"
                @change="filterModalRemoveByYear($event)"
                :value="0"
                :options="yearsModal"
                v-model="selectRemove"
              ></b-form-select>

              <b-button
                variant="btn btn-outline-green mt-1 ml-2 mr-3 mb-4"
                @click="restorei250Filter(listI250Removed)"
              >
                Restaurar itens filtrados
              </b-button>
            </div>

            <table class="table">
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th>Data de lançamento</th>
                  <th>Valor do i250</th>
                  <th>Indicador de Créd.</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style="cursor: pointer !important"
                  v-for="(item, index) in listI250Removed"
                  :key="index"
                  @click.stop="restorei250(item)"
                >
                  <td>{{ item.hist }}</td>
                  <td>{{ item.i200DtLcto }}</td>
                  <td>{{ formatMoney(item.vlDc) }}</td>
                  <b-td>{{ item.indDc }} </b-td>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </b-tab>
      </b-tabs>

      <template #modal-footer>
        <b-button variant="btn btn-outline-green" @click="i250ConfirmModal()">
          Concluir
        </b-button>
        <b-button
          variant="btn btn-outline-danger"
          @click="$bvModal.hide('modal-exclusao-i250')"
        >
          Cancelar
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import DotAnimation from "@/Components/Utils/DotAnimation.vue";
import emitter from "@/services/emitter";
import helperService from "@/services/helper.service";
import InsumosService from "@/services/teses/insumos.service";
import Icon from "../../Icon/icon.vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
  name: "Passo2Insumos",

  components: {
    DotAnimation,
    Icon,
  },

  emits: ["isStepBlocked"],

  data() {
    return {
      searchWord: "",
      searchWordModal: "",
      selectAdded: "",
      selectRemove: "",
      selectStatus: "",
      selectStatusCred: "",
      listInsumos: [],
      backupListInsumos: [],
      totalItensInsumos: 0,
      hasInsumos: false,
      isLoaded: false,
      yearsModal: [],
      statusDesc: {
        0: "Todos",
        1: "Não visualizado",
        2: "Selecionado",
        3: "Finalizado",
        4: "Erro",
        5: "Erro Remover",
        6: "Processando",
        7: "Processando Remover",
      },
      i050selected: "",
      tablebusyi250: false,
      listI250Added: [],
      backupI250Added: [],
      listI250Removed: [],
      backupI250Removed: [],
      dot: ".",
      responseInsumos: null,
      listCreditosUtilizados: [],
      backupListCreditosUtilizados: [],
      yearsCreditos: [],
      totalItensCreditos: 0,
      listItensEmProcessamento: [],
      listEmProcessamentoRemoved: [],
      isCreditosUtilizados: false,
      isItemInProcess: false,
    };
  },

  props: {
    nCodOp: String,
    activatePasso2: Number,
  },

  watch: {
    activatePasso2(newValue) {
      if (newValue == 2) {
        this.loadPasso2();
      }
    },

    // async listItensEmProcessamento(newValue) {
    //   console.log("listItensEmProcessamento - ", newValue);
    //   if (
    //     this.isItemInProcess == false &&
    //     this.listItensEmProcessamento.length > 0
    //   ) {
    //     var ultimoItemAdd = this.listItensEmProcessamento[0];

    //     if (
    //       ultimoItemAdd.status == 1 ||
    //       ultimoItemAdd.status == 4 ||
    //       ultimoItemAdd.status == 6
    //     ) {
    //       await this.proccessI250Confirm(ultimoItemAdd);
    //     } else if (
    //       ultimoItemAdd.status == 2 ||
    //       ultimoItemAdd.status == 5 ||
    //       ultimoItemAdd.status == 7
    //     ) {
    //       await this.proccessRemoveInsumo(ultimoItemAdd);
    //     } else {
    //       console.log("else item proc - ", ultimoItemAdd.status);
    //       var error = "Erro ao processar status. Entre em contato.";
    //       emitter.emit("makeToast", { msg: error, type: "error" });
    //     }
    //   } else {
    //     console.log("aguardando processo anterior");
    //   }
    // },

    listItensEmProcessamento: {
      handler(newValue) {
        console.log("listItensEmProcessamento - ", newValue);
        if (
          this.isItemInProcess == false &&
          this.listItensEmProcessamento.length > 0
        ) {
          var ultimoItemAdd = this.listItensEmProcessamento[0];

          if (
            ultimoItemAdd.status == 1 ||
            ultimoItemAdd.status == 4 ||
            ultimoItemAdd.status == 6
          ) {
            this.proccessI250Confirm(ultimoItemAdd);
          } else if (
            ultimoItemAdd.status == 2 ||
            ultimoItemAdd.status == 5 ||
            ultimoItemAdd.status == 7
          ) {
            this.proccessRemoveInsumo(ultimoItemAdd);
          } else {
            console.log("else item proc - ", ultimoItemAdd.status);
            var error = "Erro ao processar status. Entre em contato.";
            emitter.emit("makeToast", { msg: error, type: "error" });
          }
        } else {
          console.log("aguardando processo anterior");
        }
      },
    },

    async isItemInProcess(newValue) {
      console.log("isItemInProcess - ", newValue);
      if (
        this.isItemInProcess == false &&
        this.listItensEmProcessamento.length > 0
      ) {
        var ultimoItemAdd = this.listItensEmProcessamento[0];

        if (
          ultimoItemAdd.status == 1 ||
          ultimoItemAdd.status == 4 ||
          ultimoItemAdd.status == 6
        ) {
          await this.proccessI250Confirm(ultimoAdd);
        } else if (
          ultimoItemAdd.status == 2 ||
          ultimoItemAdd.status == 5 ||
          ultimoItemAdd.status == 7
        ) {
          await this.proccessRemoveInsumo(ultimoItemAdd);
        } else {
          console.log("else item proc - ", ultimoItemAdd.status);
          var error = "Erro ao processar status. Entre em contato.";
          emitter.emit("makeToast", { msg: error, type: "error" });
        }
      } else {
        console.log("aguardando processo anterior 2");
      }
    },

    i050selected(newValue) {
      console.log("watch i050selected - ", newValue);
    },
  },

  mounted() {
    this.loadPasso2();
    this.dotAnimate();
  },

  methods: {
    async loadPasso2() {
      await InsumosService.getInsumos(this.nCodOp, this.activatePasso2)
        .then((response) => {
          if (response.status == 200) {
            this.responseInsumos = response;
            this.loadOriginalList();
            this.hasInsumos = this.listInsumos.length != 0;
            this.getCreditosUtilizados();
          } else if (response.status == 204) {
            this.hasInsumos = this.listInsumos.length != 0;

            const msg =
              "Nenhum arquivo encontrado, tente enviar na tela de upload do passo anterior.";
            const type = "info";
            emitter.emit("makeModalDefault", { msg, type });
          }

          this.isLoaded = true;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data != null) {
            const title = "Erro ao buscar insumos !";
            const msg = error.response.data.mensagem;
            const type = "error";

            emitter.emit("makeModalWithBtnsAndTitle", { title, msg, type });
          } else {
            const msg = "Erro ao buscar insumos !";
            const type = "error";

            emitter.emit("makeModalDefault", { msg, type });
          }

          this.isLoaded = true;
        });

      this.$emit("passo2", 2);
    },

    async getCreditosUtilizados() {
      await InsumosService.getCreditosUtilizados(this.nCodOp)
        .then((response) => {
          if (response.status == 200) {
            this.listCreditosUtilizados = response.data.dados;
            this.backupListCreditosUtilizados = response.data.dados;
            this.totalItensCreditos = response.data.dados.length;
          }
        })
        .catch((error) => {
          this.$toast.open({ message: error, type: "error", duration: 2000 });
        });
    },

    getItensModal(i050) {
      this.tablebusyi250 = true;
      this.i050selected = i050;
      this.yearsModal = [];
      this.$refs["modal-exclusao-i250"].show();

      InsumosService.getItensModal(i050).then((response) => {
        this.listI250Added = response.data.dados.listaI250;
        this.listI250Removed = response.data.dados.listaI250Removidos;

        this.backupI250Added = response.data.dados.listaI250;
        this.backupI250Removed = response.data.dados.listaI250Removidos;

        this.yearsModal.push("Todos");
        this.listI250Added.forEach((item) => {
          this.yearsModal.indexOf(item.i200DtLcto.slice(6, 10)) === -1 &&
            this.yearsModal.push(item.i200DtLcto.slice(6, 10));
        });

        this.listI250Removed.forEach((item) => {
          this.yearsModal.indexOf(item.i200DtLcto.slice(6, 10)) === -1 &&
            this.yearsModal.push(item.i200DtLcto.slice(6, 10));
        });

        this.selectAdded = null;
        this.selectRemove = null;
        this.searchWordModal = "";
        this.tablebusyi250 = false;
      });
    },

    removeInsumo(e, insumo) {
      e.stopPropagation();
      emitter.emit("isStepBlocked", true);
      this.i050selected = insumo;
      console.log("removeInsumo");
      // this.listInsumos[this.listInsumos.indexOf(this.i050selected)].status = 7;
      this.listInsumos[this.listInsumos.indexOf(insumo)].status = 7;
      // this.updateAnotherListRemv();
      this.listItensEmProcessamento.push(insumo);
    },

    // async proccessRemoveInsumo(insumo) {
    //   try {
    //     this.isItemInProcess = true;
    //     this.i050selected = insumo;

    //     await InsumosService.removeInsumo(this.nCodOp, insumo.codCta);
    //     this.listItensEmProcessamento.splice(
    //       this.listItensEmProcessamento.indexOf(this.i050selected),
    //       1
    //     );

    //     this.listInsumos[
    //       this.listInsumos.indexOf(this.i050selected)
    //     ].status = 1;
    //     this.updateAnotherListRemv(this.i050selected);

    //     if (this.listItensEmProcessamento.length == 0) {
    //       emitter.emit("isStepBlocked", false);
    //       this.$toast.open({
    //         message: "Processamento concluido !",
    //         type: "success",
    //         duration: 2000,
    //       });
    //     }

    //     this.isItemInProcess = false;
    //   } catch (error) {
    //     console.log("erro - removeInsumo ", error);
    //     var erro = "Erro ao remover CTA: " + insumo.codCta + " - " + insumo.cta;

    //     this.listItensEmProcessamento.splice(
    //       this.listItensEmProcessamento.indexOf(this.i050selected),
    //       1
    //     );
    //     this.listInsumos[
    //       this.listInsumos.indexOf(this.i050selected)
    //     ].status = 5;

    //     if (this.listItensEmProcessamento.length == 0) {
    //       emitter.emit("isStepBlocked", false);
    //     }
    //     emitter.emit("makeToast", { msg: erro, type: "error" });

    //     this.isItemInProcess = false;
    //   }
    // },

    async proccessRemoveInsumo(insumo) {
      try {
        this.isItemInProcess = true;
        this.i050selected = insumo;

        await InsumosService.removeInsumo(this.nCodOp, insumo.codCta);
        this.listItensEmProcessamento.splice(
          this.listItensEmProcessamento.indexOf(insumo),
          1
        );

        this.listInsumos[this.listInsumos.indexOf(insumo)].status = 1;
        this.updateAnotherListRemv(insumo);

        if (this.listItensEmProcessamento.length == 0) {
          emitter.emit("isStepBlocked", false);
          this.$toast.open({
            message: "Processamento concluido !",
            type: "success",
            duration: 2000,
          });
        }

        this.isItemInProcess = false;
      } catch (error) {
        console.log("erro - removeInsumo ", error);
        var erro = "Erro ao remover CTA: " + insumo.codCta + " - " + insumo.cta;

        this.listItensEmProcessamento.splice(
          this.listItensEmProcessamento.indexOf(insumo),
          1
        );
        this.listInsumos[this.listInsumos.indexOf(insumo)].status = 5;

        if (this.listItensEmProcessamento.length == 0) {
          emitter.emit("isStepBlocked", false);
        }
        emitter.emit("makeToast", { msg: erro, type: "error" });

        this.isItemInProcess = false;
      }
    },

    async removeI250(item) {
      console.log("removeI250 - ", item);
      this.listI250Removed.push(item);
      this.listI250Added.splice(this.listI250Added.indexOf(item), 1);

      if (this.searchWordModal.length > 0) {
        this.backupI250Added.splice(this.backupI250Added.indexOf(item), 1);
      }
      console.log("listI250Removed re - ", this.listI250Removed);

      // this.listInsumos[
      //   this.listInsumos.indexOf(this.i050selected)
      // ].status = 2;

      // this.updateAnotherList();
    },

    addI250ToList(item) {
      console.log("addI250ToList");
      this.listI250s.splice(this.listI250s.indexOf(item), 1);
      this.listi250removed.push(item);
    },

    async restorei250(item) {
      console.log("restorei250");
      this.listI250Removed.splice(this.listI250Removed.indexOf(item), 1);
      this.listI250Added.push(item);

      if (this.searchWordModal.length > 0) {
        this.backupI250Removed.splice(this.backupI250Removed.indexOf(item), 1);
      }

      // this.listInsumos[
      //   this.listInsumos.indexOf(this.i050selected)
      // ].status = 2;
      // this.updateAnotherList();
    },

    async removeI250Filter(listOriginal) {
      var listFilterToRemove = [];

      if (listOriginal.length > 0) {
        listOriginal.forEach((element) => {
          listFilterToRemove.push(element);
        });

        listFilterToRemove.forEach((item) => {
          this.listI250Removed.push(item);
          this.listI250Added.splice(this.listI250Added.indexOf(item), 1);
          this.backupI250Added.splice(this.backupI250Added.indexOf(item), 1);
        });

        this.reloadSearchModal();

        // await InsumosService.saveModalI250(
        //   this.listI250Removed,
        //   this.nCodOp,
        //   this.i050selected.codCta
        // );

        // this.listInsumos[
        //   this.listInsumos.indexOf(this.i050selected)
        // ].status = 2;
        // this.updateAnotherList();
      }
    },

    async restorei250Filter(listOriginal) {
      var listFilterToRestore = [];

      if (listOriginal.length > 0) {
        listOriginal.forEach((element) => {
          listFilterToRestore.push(element);
        });

        listFilterToRestore.forEach((item) => {
          this.listI250Removed.splice(this.listI250Removed.indexOf(item), 1);
          this.listI250Added.push(item);
          this.backupI250Removed.splice(
            this.backupI250Removed.indexOf(item),
            1
          );
        });

        this.reloadSearchModal();

        // await InsumosService.saveModalI250(
        //   this.listI250Removed,
        //   this.nCodOp,
        //   this.i050selected.codCta
        // );

        // this.listInsumos[
        //   this.listInsumos.indexOf(this.i050selected)
        // ].status = 2;
        // this.updateAnotherList();
      }
    },

    async i250ConfirmModal() {
      this.$swal({
        icon: "info",
        title: `${this.i050selected.cta}`,
        position: "top-center",
        html: `${this.listI250Removed.length} I250s estão na lista de removidos`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Continuar e selecionar ",
        cancelButtonText: `Cancelar`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-outline-green mr-2",
          cancelButton: "btn btn-outline-danger ml-2",
        },
      })
        .then((value) => {
          if (value) {
            console.log("i250ConfirmModal then");
            emitter.emit("isStepBlocked", true);

            this.listInsumos[
              this.listInsumos.indexOf(this.i050selected)
            ].status = 6;
            //this.updateAnotherList();

            if (this.isItemInProcess == true) {
              console.log("está em processo - modal");
              console.log("i050selected inter - ", this.i050selected);
              console.log("listI250Removed inter - ", this.listI250Removed);

              var objRemovidoModal = {
                codCta: this.i050selected.codCta,
                removidos: this.listI250Removed,
              };

              this.listEmProcessamentoRemoved.push(objRemovidoModal);
              this.listItensEmProcessamento.push(this.i050selected);
              console.log("objRemovidoModal - ", objRemovidoModal);
              console.log(
                "listItensEmProcessamento - ",
                this.listItensEmProcessamento
              );
              console.log(
                "listEmProcessamentoRemoved - ",
                this.listEmProcessamentoRemoved
              );
            } else {
              console.log("NÃO está em processo - modal");
              this.listItensEmProcessamento.push(this.i050selected);
              this.isItemInProcess = true;

              InsumosService.saveModalI250(
                this.listI250Removed,
                this.nCodOp,
                this.i050selected.codCta
              ).then((response) => {
                console.log("response saveModalI250 - ", response);
                this.listItensEmProcessamento.splice(
                  this.listItensEmProcessamento.indexOf(this.i050selected),
                  1
                );

                this.listInsumos[
                  this.listInsumos.indexOf(this.i050selected)
                ].status = 2;
                this.updateAnotherList(this.i050selected);

                if (this.listItensEmProcessamento.length == 0) {
                  emitter.emit("isStepBlocked", false);
                  this.$toast.open({
                    message: "Processamento concluido !",
                    type: "success",
                    duration: 2000,
                  });
                }
                this.listI250Removed.length = 0;
                this.listI250Added.length = 0;
                this.isItemInProcess = false;
              });
            }

            this.$refs["modal-exclusao-i250"].hide();
          } else {
            console.log("cancelado");
            this.listInsumos[
              this.listInsumos.indexOf(this.i050selected)
            ].status = 1;
          }
        })
        .catch((err) => {
          console.log("erro - i250ConfirmModal ", err);
          var erro =
            "Erro ao processar CTA: " +
            this.i050selected.codCta +
            " - " +
            this.i050selected.cta;

          this.listItensEmProcessamento.splice(
            this.listItensEmProcessamento.indexOf(this.i050selected),
            1
          );
          this.isItemInProcess = false;
          this.listInsumos[
            this.listInsumos.indexOf(this.i050selected)
          ].status = 4;

          if (this.listItensEmProcessamento.length == 0) {
            emitter.emit("isStepBlocked", false);
          }

          emitter.emit("makeToast", { msg: erro, type: "error" });
        });
    },

    async i250FastConfirm(e, i050) {
      e.stopPropagation();
      emitter.emit("isStepBlocked", true);
      this.i050selected = i050;
      this.listInsumos[this.listInsumos.indexOf(this.i050selected)].status = 6;
      this.listItensEmProcessamento.push(i050);
    },

    async proccessI250Confirm(i050) {
      try {
        this.isItemInProcess = true;

        console.log("i050 - ", i050);
        console.log(
          "listEmProcessamentoRemoved proccessI250Confirm - ",
          this.listEmProcessamentoRemoved
        );

        const matchingItem = this.listEmProcessamentoRemoved.find(
          (item) => item.codCta == i050.codCta
        );
        console.log("matchingItem - ", matchingItem);

        if (matchingItem) {
          this.listI250Removed = matchingItem.removidos;
        } else {
          this.listI250Removed = [];
        }

        console.log(
          "listI250Removed proccessI250Confirm - ",
          this.listI250Removed
        );
        this.i050selected = i050;
        // this.listI250Removed.length = 0;
        // this.listI250Added.length = 0;

        // await InsumosService.saveModalI250(
        //   this.listI250Removed,
        //   this.nCodOp,
        //   this.i050selected.codCta
        // );
        await InsumosService.saveModalI250(
          this.listI250Removed,
          this.nCodOp,
          i050.codCta
        );

        // this.listItensEmProcessamento.splice(
        //   this.listItensEmProcessamento.indexOf(this.i050selected),
        //   1
        // );

        this.listItensEmProcessamento.splice(
          this.listItensEmProcessamento.indexOf(i050),
          1
        );

        // this.listEmProcessamentoRemoved.splice(
        //   this.listEmProcessamentoRemoved.indexOf(this.i050selected),
        //   1
        // );
        this.listEmProcessamentoRemoved.splice(
          this.listEmProcessamentoRemoved.indexOf(i050),
          1
        );

        if (this.listItensEmProcessamento.length == 0) {
          emitter.emit("isStepBlocked", false);
          this.$toast.open({
            message: "Processamento concluido !",
            type: "success",
            duration: 2000,
          });
        }

        // this.listInsumos[
        //   this.listInsumos.indexOf(this.i050selected)
        // ].status = 2;
        this.listInsumos[this.listInsumos.indexOf(i050)].status = 2;

        // this.updateAnotherList();
        this.updateAnotherList(i050);

        this.isItemInProcess = false;
      } catch (error) {
        console.log("erro - proccessI250Confirm ", error);
        // var erro =
        //   "Erro ao processar CTA: " +
        //   this.i050selected.codCta +
        //   " - " +
        //   this.i050selected.cta;
        // this.listItensEmProcessamento.splice(
        //   this.listItensEmProcessamento.indexOf(this.i050selected),
        //   1
        // );

        // this.listEmProcessamentoRemoved.splice(
        //   this.listEmProcessamentoRemoved.indexOf(this.i050selected),
        //   1
        // );
        var erro = "Erro ao processar CTA: " + i050.codCta + " - " + i050.cta;
        this.listItensEmProcessamento.splice(
          this.listItensEmProcessamento.indexOf(i050),
          1
        );

        this.listEmProcessamentoRemoved.splice(
          this.listEmProcessamentoRemoved.indexOf(i050),
          1
        );

        // this.isItemInProcess = false;
        // this.listInsumos[
        //   this.listInsumos.indexOf(this.i050selected)
        // ].status = 4;

        this.isItemInProcess = false;
        this.listInsumos[this.listInsumos.indexOf(i050)].status = 4;

        if (this.listItensEmProcessamento.length == 0) {
          emitter.emit("isStepBlocked", false);
        }
        emitter.emit("makeToast", { msg: erro, type: "error" });
      }
    },

    async updateAnotherList(i050) {
      if (this.listInsumosOriginal.length > 0) {
        this.listInsumosOriginal[
          this.listInsumosOriginal.map((e) => e.codCta).indexOf(i050.codCta)
        ].status = 2;
      } else {
        try {
        } catch (error) {
          console.log("try catch");
          console.log(error);
        }
      }
    },

    async updateAnotherListRemv(i050) {
      if (this.listInsumosOriginal.length > 0) {
        this.listInsumosOriginal[
          this.listInsumosOriginal.map((e) => e.codCta).indexOf(i050.codCta)
        ].status = 1;
      } else {
        try {
        } catch (error) {
          console.log("try catch");
          console.log(error);
        }
      }
    },

    async loadOriginalList() {
      this.listInsumos = this.responseInsumos.data.dados.listaPlanoI050;
      this.backupListInsumos = this.responseInsumos.data.dados.listaPlanoI050;
      this.listInsumosOriginal = [];
      this.totalItensInsumos = this.responseInsumos.data.dados.totalItens;
      this.reloadSearchGeral();
    },

    loadCreditos() {
      this.reloadSearchCreditos();
      this.yearsCreditos.push("Todos");
      this.listCreditosUtilizados.forEach((item) => {
        this.yearsCreditos.indexOf(item.data_Apurada.slice(0, 4)) === -1 &&
          this.yearsCreditos.push(item.data_Apurada.slice(0, 4));
      });
      this.totalItensCreditos = this.listCreditosUtilizados.length;
    },

    async loadListaInsumos() {
      this.selectStatus = "";
      await this.loadOriginalList();
    },

    reloadSearchGeral() {
      this.isCreditosUtilizados = false;
      this.searchWord = "";
    },

    reloadSearchModal() {
      this.searchWordModal = "";
      this.listI250Added = this.backupI250Added;
      this.listI250Removed = this.backupI250Removed;
      this.orderListByYear();
      this.selectAdded = null;
      this.selectRemove = null;
    },

    reloadSearchCreditos() {
      this.isCreditosUtilizados = true;
      this.searchWordCreditos = "";
      this.yearsCreditos = [];
      this.listCreditosUtilizados = this.backupListCreditosUtilizados;
      this.selectStatusCred = "";
      this.filterCreditoByYear("Todos");
    },

    filterByStatus(statusCod) {
      statusCod = statusCod == undefined ? 0 : statusCod;
      this.searchWord = "";
      this.listInsumos = this.backupListInsumos;
      if (statusCod != 0) {
        this.listInsumos = Object.keys(this.listInsumos)
          .map((key) => this.listInsumos[key])
          .filter((item) => item.status == statusCod);
      }
      this.totalItensInsumos = this.listInsumos.length;
    },

    filterModalAddByYear(yearFilter) {
      this.searchWordModal = "";
      this.listI250Added = this.backupI250Added;
      if (yearFilter != "Todos") {
        this.listI250Added = Object.keys(this.listI250Added)
          .map((key) => this.listI250Added[key])
          .filter((item) => item.i200DtLcto.slice(6, 10) == yearFilter);
      }
    },

    filterModalRemoveByYear(yearFilter) {
      this.searchWordModal = "";
      this.listI250Removed = this.backupI250Removed;
      if (yearFilter != "Todos") {
        this.listI250Removed = Object.keys(this.listI250Removed)
          .map((key) => this.listI250Removed[key])
          .filter((item) => item.i200DtLcto.slice(6, 10) == yearFilter);
      }
    },

    filterByWord() {
      this.listInsumos = this.backupListInsumos;
      this.selectStatus = null;

      if (this.searchWord.length != 0) {
        this.listInsumos = Object.keys(this.listInsumos)
          .map((key) => this.listInsumos[key])
          .filter((item) =>
            item.cta.toUpperCase().includes(this.searchWord.toUpperCase())
          );
      }
      this.totalItensInsumos = this.listInsumos.length;
    },

    filterInsumoAdd() {
      this.listI250Added = this.backupI250Added;
      this.selectAdded = null;
      this.selectRemove = null;

      if (this.searchWordModal.length != 0) {
        this.listI250Added = Object.keys(this.listI250Added)
          .map((key) => this.listI250Added[key])
          .filter((item) =>
            item.hist.toUpperCase().includes(this.searchWordModal.toUpperCase())
          );
      }
    },

    filterInsumoRemoved() {
      this.listI250Removed = this.backupI250Removed;
      this.selectAdded = null;
      this.selectRemove = null;

      if (this.searchWordModal.length != 0) {
        this.listI250Removed = Object.keys(this.listI250Removed)
          .map((key) => this.listI250Removed[key])
          .filter((item) =>
            item.hist.toUpperCase().includes(this.searchWordModal.toUpperCase())
          );
      }
    },

    filterCreditoByYear(yearCred) {
      yearCred = yearCred == undefined ? "Todos" : yearCred;
      this.searchWordCreditos = "";
      this.listCreditosUtilizados = this.backupListCreditosUtilizados;
      if (yearCred != "Todos") {
        this.listCreditosUtilizados = Object.keys(this.listCreditosUtilizados)
          .map((key) => this.listCreditosUtilizados[key])
          .filter((item) => item.data_Apurada.slice(0, 4) == yearCred);
      }
      this.totalItensCreditos = this.listCreditosUtilizados.length;
    },

    filterCreditoByWord() {
      this.listCreditosUtilizados = this.backupListCreditosUtilizados;
      this.selectStatusCred = "null";

      if (this.searchWordCreditos.length != 0) {
        this.listCreditosUtilizados = Object.keys(this.listCreditosUtilizados)
          .map((key) => this.listCreditosUtilizados[key])
          .filter((item) =>
            item.descricao
              .toUpperCase()
              .includes(this.searchWordCreditos.toUpperCase())
          );
      }
      this.totalItensCreditos = this.listCreditosUtilizados.length;
    },

    dotAnimate() {
      setInterval(() => {
        setTimeout(() => {
          this.dot = ". .";
        }, 1000);

        setTimeout(() => {
          this.dot = ". . .";
        }, 2000);
      }, 3000);
    },

    orderListByYear() {
      this.listI250Added.sort(function (a, b) {
        if (a.i200DtLcto.slice(6, 10) > b.i200DtLcto.slice(6, 10)) {
          return 1;
        }
        if (a.i200DtLcto.slice(6, 10) < b.i200DtLcto.slice(6, 10)) {
          return -1;
        }
        return 0;
      });
      this.listI250Removed.sort(function (a, b) {
        if (a.i200DtLcto.slice(6, 10) > b.i200DtLcto.slice(6, 10)) {
          return 1;
        }
        if (a.i200DtLcto.slice(6, 10) < b.i200DtLcto.slice(6, 10)) {
          return -1;
        }
        return 0;
      });
    },

    colorPillStatus(status) {
      let colorPill = "";
      switch (status) {
        case 1:
          colorPill = "#00B087";
          break;
        case 2:
          colorPill = "#00B087";
          break;
        case 3:
          colorPill = "success";
          break;
      }

      return colorPill;
    },

    formatMoney(number) {
      return helperService.formatMoneyPtBR(number);
    },

    formatDatePTBR(date) {
      const formatDate = date.split("T")[0].split("-").reverse().join("/");
      return formatDate;
    },
  },
};
</script>

<style scoped></style>
