<template>
  <section>
    <div v-if="isLoaded">
      <b-overlay
        :show="loadingSpinner"
        rounded
        opacity="0.5"
        spinner-small
        spinner-variant="secondary"
        style="padding: 1px 0px"
      >
        <div v-if="hasInsumosSelected">
          <div class="export-container">
            <div v-if="totalPisCofins <= 0" class="export-card">
              <div>Realizar Cálculos e Exportar</div>
              <div class="export-card-buttons d-flex justify-content-center">
                <button
                  class="btn btn-outline-blue m-3"
                  :disabled="isDisabledAll"
                  @click.prevent="updateResultInsumos($event)"
                >
                  Calcular
                </button>
              </div>
            </div>

            <div v-if="totalPisCofins > 0" class="export-card">
              <div>Exportar 100%</div>
              <div class="export-card-buttons d-flex justify-content-center">
                <button
                  class="btn btn-outline-blue m-3"
                  :disabled="isDisabledAll"
                  @click="exportInsumosMensal()"
                >
                  Mensal .XLSX
                </button>
                <button
                  class="btn btn-outline-blue m-3"
                  :disabled="isDisabledAll"
                  @click="exportInsumos(0)"
                >
                  Anual .XLSX
                </button>
                <button
                  class="btn btn-outline-blue m-3"
                  :disabled="isDisabledAll"
                  @click="exportPDF(0)"
                >
                  Anual .PDF
                </button>
              </div>
            </div>

            <div v-if="totalPisCofins > 0" class="export-card">
              <div>Exportar 70%</div>
              <div class="export-card-buttons">
                <button
                  class="btn btn-outline-blue m-3"
                  :disabled="isDisabledAll"
                  @click="exportInsumos(1)"
                >
                  Anual .XLSX
                </button>
                <button
                  class="btn btn-outline-blue m-3"
                  :disabled="isDisabledAll"
                  @click="exportPDF(1)"
                >
                  Anual .PDF
                </button>
              </div>
            </div>
          </div>
          <div class="resume-container">
            <b-card class="resume-list" no-body>
              <b-button
                class="upload-list-header"
                block
                v-b-toggle.accordion-2
                variant="light"
              >
                <div>Insumos Selecionados</div>
                <div class="upload-list-header-buttons">
                  <div class="upload-list-count">
                    {{
                      totalPisCofins > 0
                        ? "Créditos a Recuperar: " + formatMoney(totalPisCofins)
                        : "Atualize o cálculo"
                    }}
                  </div>
                  <button @click.prevent="updateResultInsumos($event)">
                    <Icon :iconName="'arrow-clockwise'" class="nav-icon" />
                  </button>
                  <button>
                    <Icon :iconName="'chevron-down'" class="nav-icon" />
                  </button>
                </div>
              </b-button>

              <b-collapse
                v-if="this.resultInsumos.length > 0"
                class="upload-list-collapse resume-table"
                id="accordion-2"
                accordion="my-accordion"
                role="tabpanel"
              >
                <table class="table table-hover" style="max-width: 100%">
                  <thead>
                    <tr>
                      <th><strong>Ano</strong></th>
                      <th><strong>Descrição do Insumo</strong></th>
                      <th><strong>Valor Base</strong></th>
                      <th><strong>Total Pis</strong></th>
                      <th><strong>Total Cofins</strong></th>
                      <th><strong>Soma Total Pis/Cofins</strong></th>
                      <th style="display: flex; justify-content: center">
                        <strong>Ação</strong>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(insumo, index) in this.resultInsumos"
                      :key="index"
                    >
                      <td>
                        {{ getYearFromDate(insumo.dataApuracao) }}
                      </td>

                      <td>
                        {{ insumo.descricaoVerba }}
                      </td>

                      <td>
                        {{ formatMoney(insumo.valorBase) }}
                      </td>

                      <td>
                        {{ formatMoney(insumo.valorPis) }}
                      </td>

                      <td>
                        {{ formatMoney(insumo.valorCofins) }}
                      </td>

                      <td>
                        {{ formatMoney(insumo.valorTotal) }}
                      </td>

                      <td style="display: flex; justify-content: center">
                        <button
                          type="button"
                          :disabled="isDisabled"
                          @click="removeInsumo(insumo)"
                          class="btn btn-sm btn-outline-danger"
                        >
                          <font-awesome-icon icon="trash-can" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2"><strong>Total:</strong></td>
                      <td>
                        <strong>{{ formatMoney(totalIcms) }}</strong>
                      </td>
                      <td>
                        <strong>{{ formatMoney(totalPis) }}</strong>
                      </td>
                      <td>
                        <strong>{{ formatMoney(totalCofins) }}</strong>
                      </td>
                      <td>
                        <strong>{{ formatMoney(totalPisCofins) }}</strong>
                      </td>
                      <td style="display: flex; justify-content: center">
                        <button
                          type="button"
                          :disabled="isDisabledAll"
                          @click="removeAllInsumos()"
                          class="btn btn-sm btn-outline-danger"
                        >
                          Remover Todos
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </b-collapse>
            </b-card>
          </div>
        </div>

        <div v-else class="export-container">
          <h2 class="mt-5 text-secondary w-100 text-center">
            Nenhum insumo selecionado para cálculo.
          </h2>
        </div>
      </b-overlay>
    </div>

    <div v-else>
      <div class="p-5 d-flex justify-content-center">
        <h1 class="text-secondary d-flex align-items-end">
          <span class="mx-3">Carregando</span>
          <DotAnimation />
        </h1>
      </div>
    </div>
  </section>
</template>

<script>
import DotAnimation from "@/Components/Utils/DotAnimation.vue";
import emitter from "@/services/emitter";
import HelperService from "@/services/helper.service";
import InsumosService from "@/services/teses/insumos.service";
import insumosService from "../../../services/teses/insumos.service";
import Icon from "@/Components/Icon/icon.vue";

export default {
  name: "Passo3Insumos",

  components: {
    DotAnimation,
    Icon,
  },

  data() {
    return {
      loadingSpinner: false,
      searchWord: "",
      searchWordModal: "",
      selectAdded: "",
      selectRemove: "",
      valorTotalVerbas: "",
      selectStatus: "",
      listInsumos: [],
      backupListInsumos: [],
      totalItensInsumos: 0,
      hasInsumosSelected: false,
      isLoaded: false,
      yearsModal: [],
      statusDesc: {
        0: "Todos",
        1: "Não visualizado",
        2: "Selecionado",
        3: "Finalizado",
      },
      tablebusyi250: false,
      dot: ".",
      responseInsumos: null,
      isDisabled: false,
      isDisabledAll: false,
      nomeCliente: "",
      dia: "",
      mes: "",
      ano: "",
      hora: "",
      minuto: "",
      segundo: "",
      nomeArquivoConcat: "",
      totalIcms: 0,
      totalPis: 0,
      totalCofins: 0,
      totalPisCofins: -1,
      resultInsumos: [],
    };
  },

  props: {
    nCodOp: String,
    activatePasso3: Number,
  },

  watch: {
    activatePasso3(newValue) {
      if (newValue == 2) {
        this.loadPasso3();
      }
    },
  },

  mounted() {
    this.loadPasso3();
    this.dotAnimate();
    this.getCliente(this.nCodOp);
  },

  methods: {
    loadPasso3() {
      InsumosService.checkHasInsumosPasso3(this.nCodOp)
        .then((response) => {
          if (response.status == 200) {
            this.hasInsumosSelected = response.data;
            this.updateResultInsumos(null).then((response) => {
              this.getresultInsumos();
            });
          } else if (response.status == 204) {
            this.hasInsumosSelected = false;
            const msg = "Nenhum insumo encontrado.";
            const type = "info";
            emitter.emit("makeModalDefault", { msg, type });
          }
          this.isLoaded = true;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data != null) {
            const title = "Erro ao buscar insumos !";
            const msg = error.response.data.mensagem;
            const type = "error";

            emitter.emit("makeModalWithBtnsAndTitle", { title, msg, type });
          } else {
            const msg = "Erro ao buscar insumos !";
            const type = "error";

            emitter.emit("makeModalDefault", { msg, type });
          }

          this.isLoaded = true;
        });

      this.$emit("passo3", 3);
    },

    async updateResultInsumos(e) {
      if (e != null || e != undefined)
        e.stopPropagation(), (this.loadingSpinner = true);
      await InsumosService.updateResultInsumos(this.nCodOp)
        .then((response) => {
          if (response.status == 200) this.resultInsumos = response.data.dados;
        })
        .catch((error) => {
          this.$toast.open({ message: error, type: "error", duration: 2000 });
        });

      var newTotalIcms = 0;
      var newTotalPis = 0;
      var newTotalCofins = 0;
      var newTotalPisCofins = 0;

      this.resultInsumos.forEach((element) => {
        newTotalIcms = newTotalIcms + element.valorBase;
        newTotalPis = newTotalPis + element.valorPis;
        newTotalCofins = newTotalCofins + element.valorCofins;
        newTotalPisCofins = newTotalPisCofins + element.valorTotal;
      });

      this.totalIcms = Math.round(newTotalIcms * 100) / 100;
      this.totalPis = Math.round(newTotalPis * 100) / 100;
      this.totalCofins = Math.round(newTotalCofins * 100) / 100;
      this.totalPisCofins = Math.round(newTotalPisCofins * 100) / 100;
      this.loadingSpinner = false;
    },

    async getresultInsumos() {
      (this.loadingSpinner = true),
        await InsumosService.getResultInsumos(this.nCodOp)
          .then((response) => {
            if (response.status == 200) {
              this.resultInsumos = response.data.dados;
            }
          })
          .catch((error) => {
            this.$toast.open({ message: error, type: "error", duration: 2000 });
          });

      var newTotalIcms = 0;
      var newTotalPis = 0;
      var newTotalCofins = 0;
      var newTotalPisCofins = 0;

      this.resultInsumos.forEach((element) => {
        newTotalIcms = newTotalIcms + element.valorBase;
        newTotalPis = newTotalPis + element.valorPis;
        newTotalCofins = newTotalCofins + element.valorCofins;
        newTotalPisCofins = newTotalPisCofins + element.valorTotal;
      });

      this.totalIcms = Math.round(newTotalIcms * 100) / 100;
      this.totalPis = Math.round(newTotalPis * 100) / 100;
      this.totalCofins = Math.round(newTotalCofins * 100) / 100;
      this.totalPisCofins = Math.round(newTotalPisCofins * 100) / 100;
      this.loadingSpinner = false;
    },

    //reduzido 0 relatorio normal - reduzido 1 relatorio reduzido
    async exportInsumos(reduzido) {
      this.$toast.open({
        message: "Aguarde enquanto o relatório é processado.",
        type: "info",
        duration: 4000,
      });
      this.isDisabledAll = this.isDisabled = this.showOverlay = true;
      this.$emit("disabledBtnPasso", true);

      await InsumosService.exportRelatorio(this.nCodOp, reduzido)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          this.montaData();
          var nomeReduzido = reduzido == 1 ? "Reduzido - " : " ";
          this.nomeArquivoConcat =
            "Relatório Insumos Anual - " +
            nomeReduzido +
            this.nomeCliente +
            " - " +
            this.dataCompleta;
          link.setAttribute("download", this.nomeArquivoConcat + ".xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log("e -", err);
          const msg = "Erro ao exportar Relatório Insumos: ";
          const type = "error";
          emitter.emit("makeModalDefault", { msg, type });
          this.isDisabledAll = this.isDisabled = this.showOverlay = false;
          this.$emit("disabledBtnPasso", false);
        });

      this.isDisabledAll = this.isDisabled = this.showOverlay = false;
      this.$emit("disabledBtnPasso", false);
      //HelperService.callMakeToast("Processamento concluído!", "success", "2000");
      this.$toast.open({
        message: "Processamento concluído!",
        type: "success",
        duration: 4000,
      });
    },

    async exportInsumosMensal() {
      this.$toast.open({
        message: "Aguarde enquanto o relatório é processado.",
        type: "info",
        duration: 4000,
      });
      this.isDisabledAll = this.isDisabled = this.showOverlay = true;
      this.$emit("disabledBtnPasso", true);

      await InsumosService.exportRelatorioMensal(this.nCodOp)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          this.montaData();
          this.nomeArquivoConcat =
            "Relatório Insumos Mensal - " +
            this.nomeCliente +
            " - " +
            this.dataCompleta;
          link.setAttribute("download", this.nomeArquivoConcat + ".xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log("e -", err);
          const msg = "Erro ao exportar Relatório Mensal Insumos: ";
          const type = "error";
          emitter.emit("makeModalDefault", { msg, type });
          this.isDisabledAll = this.isDisabled = this.showOverlay = false;
          this.$emit("disabledBtnPasso", false);
        });

      this.isDisabledAll = this.isDisabled = this.showOverlay = false;
      this.$emit("disabledBtnPasso", false);
      //HelperService.callMakeToast("Processamento concluído!", "success", "2000");
      this.$toast.open({
        message: "Processamento concluído!",
        type: "success",
        duration: 4000,
      });
    },

    async exportPDF(reduzido) {
      this.$toast.open({
        message: "Aguarde enquanto o relatório é processado.",
        type: "info",
        duration: 4000,
      });
      this.isDisabledAll = this.isDisabled = this.showOverlay = true;
      this.$emit("disabledBtnPasso", true);

      await InsumosService.exportPDF(this.nCodOp, reduzido)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/pdf",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          this.montaData();
          var nomeReduzido = reduzido == 1 ? "Reduzido - " : " ";
          this.nomeArquivoConcat =
            "Relatório Insumos - " +
            nomeReduzido +
            this.nomeCliente +
            " - " +
            this.dataCompleta;
          link.setAttribute("download", this.nomeArquivoConcat + ".pdf");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log("e -", err);
          const msg = "Erro ao exportar Relatório Insumos: ";
          const type = "error";
          emitter.emit("makeModalDefault", { msg, type });
          this.isDisabledAll = this.isDisabled = this.showOverlay = false;
          this.$emit("disabledBtnPasso", false);
        });

      this.isDisabledAll = this.isDisabled = this.showOverlay = false;
      this.$emit("disabledBtnPasso", false);
      //HelperService.callMakeToast("Processamento concluído!", "success", "2000");
      this.$toast.open({
        message: "Processamento concluído!",
        type: "success",
        duration: 4000,
      });
    },

    async getCliente() {
      await InsumosService.getCliente(this.nCodOp)
        .then((response) => {
          if (response.status == 200) {
            this.nomeCliente = response.data;
          }
        })
        .catch((error) => {
          return HelperService.callMakeToast(error, "error");
        });
    },

    montaData() {
      var dataAtual = new Date();
      this.dia = dataAtual.getDate();
      this.mes = dataAtual.getMonth() + 1;
      this.ano = dataAtual.getFullYear();
      this.hora = dataAtual.getHours();
      this.minuto = dataAtual.getMinutes();
      this.segundo = dataAtual.getSeconds();
      this.dataCompleta =
        this.dia +
        "/" +
        this.mes +
        "/" +
        this.ano +
        "/" +
        "_" +
        this.hora +
        ":" +
        this.minuto +
        ":" +
        this.segundo;
    },

    removeInsumo(insumo) {
      this.loadingSpinner = true;
      this.isDisabled = this.isDisabledAll = this.showOverlay = true;

      insumosService
        .removeInsumo(this.nCodOp, insumo.codigoCta)
        .then((response) => {
          if (response.data) {
            this.resultInsumos.splice(this.resultInsumos.indexOf(insumo), 1);
          } else {
            console.log("Erro removeInsumo");
          }
          this.isDisabled = this.isDisabledAll = this.showOverlay = false;
          this.updateResultInsumos(null);
          this.hasInsumosSelected = this.resultInsumos.length != 0;
        });
    },

    async removeAllInsumos() {
      this.loadingSpinner = true;
      this.isDisabled = this.isDisabledAll = this.showOverlay = true;

      await insumosService.removeAllInsumos(this.nCodOp).then((response) => {
        if (response.data) {
          this.resultInsumos = [];
          this.totalPisCofins = 0;
          this.hasInsumosSelected = false;
          this.isDisabled = this.isDisabledAll = this.showOverlay = false;
        } else {
          console.log("Erro removeAllInsumos");
          this.isDisabled = this.isDisabledAll = this.showOverlay = false;
        }
      });
      this.updateResultInsumos(null);
    },

    dotAnimate() {
      setInterval(() => {
        setTimeout(() => {
          this.dot = ". .";
        }, 1000);

        setTimeout(() => {
          this.dot = ". . .";
        }, 2000);
      }, 3000);
    },

    formatMoney(number) {
      return HelperService.formatMoneyPtBR(number);
    },

    formatMoneyPtBR(value) {
      return HelperService.formatMoneyPtBR(value);
    },

    formatSimpleDate(date) {
      return HelperService.formatSimpleDate(date);
    },

    getYearFromDate(date) {
      return HelperService.getYearFromDate(date);
    },
  },
};
</script>

<style scoped>
.resume-container {
  display: flex;
  justify-content: space-around;
  margin: 50px 0px;
}
.resume-list {
  width: 90%;
  margin: 20px;
  border: none;
  box-shadow: 18px 18px 36px #bebebe, -18px -18px 36px #ffffff;
  border-radius: 10px;
  display: flex;
  justify-items: center;
}
.resume-table {
  padding: 0px 20px;
}
.upload-list-header {
  background-color: white;
  color: black;
  border: none;
  border-radius: 10px;
  border-bottom: #233873;
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  padding-left: 30px;
}
.upload-list-header-buttons button {
  background: none;
  border: none;
}

.upload-list-header div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.upload-list-count {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding: 15px;
  letter-spacing: -0.02em;

  color: #8e8e8e;
}
.export-container {
  display: flex;
  justify-content: space-around;
  margin: 80px 0px 80px 0px;
}

.export-card {
  background-color: white;
  color: black;
  border-radius: 10px;
  min-height: 200px;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);

  padding-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
}

.table {
  max-width: 90%;
}

table tfoot {
  margin-top: 500px;
}

table tbody {
  margin-bottom: 200px;
}
</style>
