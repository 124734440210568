<template>  
    <section id="Insumos" >  
      <div class="cabecalho d-flex justify-content-between">
        <Breadcrumb/>
      </div> 
  
      <div class="jumbotron">  
        <div class="container-calculo">
            <Stepper class="stepper" :steps="this.steps" :currentStep="passo" @actualStep="passo=$event" :availableStep="this.validateStepBlocked()"/>
        </div>

        <div v-if="passo == 1" class="uploadComponentInsumos mt-3" > 
            <UploadComponentInsumos class="uploadComponentInsumos"/>
        </div>

        <div v-else-if="passo == 2" class="mt-5">
          <Passo2Insumos :nCodOp="nCodOp" @passo2="passo = $event" :activatePasso2="passo" />
        </div>

        <div v-else-if="passo == 3" class="mt-5">
          <Passo3Insumos :nCodOp="nCodOp" @passo3="passo = $event" :activatePasso3="passo" />
        </div>

      </div>
    </section>
  </template>
  
  <script>
  
  import emitter from "@/services/emitter";
  import InsumosService from "@/services/teses/insumos.service";
  import UploadComponentInsumos from "@/Components/UploadComponent/UploadComponentInsumos.vue";
  import Breadcrumb from "@/Components/Breadcrumb/Breadcrumb.vue";
  import Stepper from "@/Components/Stepper/Stepper.vue";
  import Passo2Insumos from "@/Components/Teses/Insumos/Passo2Insumos.vue"
  import Passo3Insumos from "@/Components/Teses/Insumos/Passo3Insumos.vue"

  
  export default {
    name: "Insumos",
  
    components: {
        Breadcrumb,
        Stepper,
        UploadComponentInsumos,
        Passo2Insumos,
        Passo3Insumos,
    },
    data(){
      return{
          passo: 1,
          hasFiles: "",
          nCodOp: "",
          hasContent: false,
          isStepBlocked: false,
          availableStep: 1,
          dot: ".",
          steps: [
                  {
                      stepNumber: 1,
                      stepTitle: "Enviar Arquivos",
                  },
                  {
                      stepNumber: 2,
                      stepTitle: "Lista de Insumos",
                  },
                  {
                      stepNumber: 3,
                      stepTitle: "Relatórios",
                  },
              ],
      }
    },
  
    watch:{
    },
    created(){
      this.nCodOp = this.$route.params.nCodOp;
    },
    async mounted(){

        emitter.on("isStepBlocked", (bool) => {
            this.isStepBlocked = bool;
        });

        await this.checkFilesInsumos();
        this.validateStepBlocked();
    },
    methods: {
      async checkFilesInsumos(){
          await InsumosService.checkFilesInsumos(this.nCodOp)
              .then((resp) => {
                  this.hasFiles = resp.data;
              })
              .catch((error) => {
                  if(error.response.data != null) {
                      this.$swal({
                          icon: "error",
                          title: "Erro ao verificar arquivos!",
                          text: error.response.data.mensagem,
                          type: "error",
                          showCancelButton: false,
                          confirmButtonColor: "#3055d6",
                          confirmButtonText: "Ok",
                      })
                      .then(() => {
                          this.$router.push('/home');
                      })
                  }
                  else{
                      const msg = "Erro ao verificar arquivos.";
                      const type = "error";
                      emitter.emit("makeModalDefault", {msg, type});
                  }
              });
      },
  
      dotAnimate(){
          setInterval(() => {
              setTimeout(() => {
                  this.dot = ". ."
              }, 500)
  
              setTimeout(() => {
                  this.dot = ". . ."
              }, 750)
          }, 1000)
          return this.dot
      },

      validateStepBlocked(){
        console.log("step blocked validateStepBlocked ? -> ", this.isStepBlocked);
        if(this.isStepBlocked){
            return this.availableStep = this.passo;
        }
        else{
            return this.availableStep = this.passo+1;
        }
      }

    },
  };
  </script>
  
  <style>
    #Teses{
        min-height: calc(100vh - 130px);
    }
    .container-calculo{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .uploadComponentInsumos{
      width: 100% !important;
    }
  
    .tese{
        flex: 1 0 15%;
        display: flex;
        flex-direction: column-reverse;
  
        height: 22vh;
        margin: 2%;
  
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.35);
        border-radius: 5px;
        cursor: pointer;
  
        font-size: 28px;
        font-weight: 700;
        color: white;
        text-align: center;
    }
  
    .btn-acessar{
        transition: 0.5s;
  
        background: white;
        padding: 6px 0px;
        border-radius: 0px 0px 5px 5px;
        width: 100%;
  
        font-size: 18px;
        font-weight: 400px;
        color: gray;
    }
  
    .tese-green{
        transition: 0.5s;
        background: rgba(43, 182, 115, 0.85);
    }
    .tese-green:hover{
        font-size: 30px;
        background: #2BB673;
    }
    
    .tese-blue{
        transition: 0.5s;
        background: rgba(48, 48, 132, 0.85);
    }
    .tese-blue:hover{
        font-size: 30px;
        background: #303086;
    }
  
    .tese-gray{
        transition: 0.5s;
        background: #e3e3e3;
    }
    .tese-gray:hover{
        background: #ffc4ca;
    }
    .tese-gray:hover .btn-acessar{
        color: #E82134;
    }
  
  
  </style>